import React, { useCallback, useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  Button,
  Collapse,
  Menu,
  MenuItem,
  Divider,
  Stack,
  Paper,
  ListItemText,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FolderIcon from '@mui/icons-material/Folder';
import CourseActions from './CourseActions';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import {
  addCourseItem,
  assignResultToItem,
  deleteCourseItem,
  updateCourseItem,
} from '../../redux/slices/courseItemSlice';
import {
  setCourseItems,
  setLoading,
  setError,
} from '../../redux/slices/courseItemSlice';
import {
  addContactLinkToCourse,
  addLearningObjectiveToCourse,
  addLinkToCourse,
  createNewLinkExam,
  createNewSubFolder,
  deleteExamById,
  deleteLearningObjectiveFromCourse,
  deleteLinkExamById,
  deleteLinkFromCourse,
  deletePDFById,
  deleteSubFolderById,
  deleteVideoById,
  fetchRate,
  fetchResultByStudentId,
  getCourseById,
  updateItemParentFolderId,
  updateSubFolderById,
} from '../../api';
import useAuth from '../../hooks/useAuth';
import CourseInfoSection from './CourseInfoSection';
import DialogForm from './DialogForm';
import ExamTabs from '../Exams';
import { CourseStats, ListItemIconCustom, WrapText } from '../Home/Course';
import DeleteIcon from '@mui/icons-material/Delete';
import { appActions } from '../../redux/slices/appSlice';
import ConfirmDialog from '../Home/ComfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import VideoPdfPage from '../VideoPdf';
import SubFolderDialogItem from './SubFolderDialogItem';
import { vi } from 'date-fns/locale';
import LoadingDisplay from '../Home/Loading';
import ErrorDisplay from '../Home/Error';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddButton from './AddButton';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import useResponsive from '../../hooks/useResponsive';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { fetchBuyCourses } from '../../api';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ImageComponent from '../Home/ImageComponent';
import { defaultExam, Result } from '../Exams/type';
import TruncatedTextWithDialog from '../Exams/TruncatedTextWithDialog';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { CalculateScore, getRank } from '../Exams/extractQuestions';
import LinkIcon from '@mui/icons-material/Link';
import { PriorityHigh } from '@mui/icons-material';

const CoursesPage = () => {
  const { user } = useAuth();
  const { courseId } = useParams();
  const courseContent = useSelector(
    (state: RootState) => state.courseItems.course
  );
  console.log('courseContent', courseContent);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [dialogItemType, setDialogItemType] = useState<'subfolder' | 'other'>(
    'other'
  );
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogMaxWidth, setDialogMaxWidth] = useState<
    'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  >('xl');
  const [openSubfolders, setOpenSubfolders] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [dialogContent, setDialogContent] = useState<React.ReactNode | null>(
    null
  );
  const [activeItem, setActiveItem] = useState({
    itemId: '',
    type: '',
    _id: '',
  });
  const [loadingDelete, setLoadingDelete] = useState(false);
  const loading = useSelector((state: RootState) => state.courseItems.loading);
  const error = useSelector((state: RootState) => state.courseItems.error);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isTeacher =
    !!user &&
    (user.role === 'admin' ||
      (user.role === 'teacher' && courseContent.createdBy._id === user._id));
  const [sortedResults, setSortedResults] = useState<Result[] | null>(null);
  const [isViewScore, setIsViewScore] = useState(false);

  // const toggleDrawer =
  //   (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
  //     setIsDrawerOpen(open);
  //   };

  const [isMember, setIsMember] = useState(false);
  const [rate, setRate] = useState<number>(-1);

  useEffect(() => {
    const fetchResults = async () => {
      if (!user?._id) return;
      try {
        const res = await fetchResultByStudentId(user._id);
        const sortedResults: Result[] = res;

        if (sortedResults) {
          const resultsWithScores = sortedResults.map((result) => ({
            ...result,
            score: result.exam
              ? CalculateScore(result.answers, result.exam.questions)
              : 0,
            rank: getRank(result),
          }));

          setSortedResults(resultsWithScores);
        }
      } catch (error) {
        console.error(error);
        setError('Đã xảy ra lỗi. Vui lòng thử lại sau.');
      }
    };
    fetchResults();
  }, [user?._id, dispatch]);

  useEffect(() => {
    sortedResults && dispatch(assignResultToItem(sortedResults));
  }, [sortedResults, dispatch, courseContent.items]);

  // Recursive function to add subfolders to the openSubfolders array
  const addSubfolders = useCallback((items: any[] = []) => {
    items.forEach((item) => {
      if (item.type === 'subfolder') {
        setOpenSubfolders((prev) => [...prev, item.itemId]);
        addSubfolders(item.items);
      }
    });
  }, []);

  useEffect(() => {
    const loadCourse = async () => {
      dispatch(setLoading(true)); // Bắt đầu tải
      try {
        if (courseId) {
          const data = await getCourseById(courseId);
          dispatch(setCourseItems(data)); // Gửi action setCourseItems
          // Add to openSubfolders
          const subfolders = data.items.filter(
            (item: any) => item.type === 'subfolder'
          );
          addSubfolders(subfolders);
          // const subfolderIds = subfolders.map((item: any) => item.itemId);
          // setOpenSubfolders(subfolderIds);
        } else {
          throw new Error('No course ID provided');
        }
      } catch (err) {
        console.error('Lỗi khi tải khóa học:', err);
        dispatch(setError('Không thể tải khóa học'));
      } finally {
        dispatch(setLoading(false)); // Dừng tải
      }
    };

    loadCourse();
  }, [courseId, dispatch, addSubfolders]);

  useEffect(() => {
    const loadCourseStatus = async () => {
      if (!user || !user._id || !courseId) {
        setIsMember(false);
        setRate(-1);
        return;
      }
      try {
        // const favoriteData = await fetchFavorites(user._id, courseId);
        // setIsFavorite(favoriteData.isFavorite);

        const buyCourseData = await fetchBuyCourses(user._id, courseId);
        setIsMember(buyCourseData.isBuyCourse);
        const rateData = await fetchRate(user._id, courseId);
        setRate(rateData.rate);
      } catch (err) {
        console.error('Error fetching course data:', err);
      }
    };

    loadCourseStatus();
  }, [user, courseId]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseMenu2 = () => {
    setAnchorEl2(null);
  };

  const handleAddLink = async (link: string) => {
    try {
      // Cập nhật khóa học với liên kết mới
      if (!courseId) return;

      await addLinkToCourse(courseId, link);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi thêm liên kết:', err);
      dispatch(setError('Không thể thêm liên kết'));
    }
  };

  const handleDeleteLink = async (link: string) => {
    try {
      // Xóa liên kết khỏi khóa học
      if (!courseId) return;

      await deleteLinkFromCourse(courseId, link);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi xóa liên kết:', err);
      dispatch(setError('Không thể xóa liên kết'));
    }
  };

  const handleAddContactLink = async (link: string) => {
    try {
      // Cập nhật khóa học với liên kết liên hệ mới
      if (!courseId) return;

      await addContactLinkToCourse(courseId, link);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi thêm liên kết:', err);
      dispatch(setError('Không thể thêm liên kết'));
    }
  };

  const handleAddLearningObjective = async (objective: string) => {
    try {
      // Cập nhật khóa học với mục tiêu học tập mới
      if (!courseId) return;
      await addLearningObjectiveToCourse(courseId, objective);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi thêm mục tiêu học tập:', err);
      dispatch(setError('Không thể thêm mục tiêu học tập'));
    }
  };

  const handleDeleteLearningObjective = async (objective: string) => {
    try {
      // Xóa mục tiêu học tập khỏi khóa học
      if (!courseId) return;

      await deleteLearningObjectiveFromCourse(courseId, objective);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi xóa mục tiêu học tập:', err);
      dispatch(setError('Không thể xóa mục tiêu học tập'));
    }
  };

  const handleCreateSubFolder = async (
    name: string,
    parentFolderId: string
  ) => {
    try {
      if (!courseId) return;
      const res = await createNewSubFolder(courseId, parentFolderId, name);
      const newSubFolderWithType = {
        ...res.data,
        type: 'subfolder',
        itemId: res.data.subFolderId,
      };
      dispatch(addCourseItem(newSubFolderWithType));
    } catch (err) {
      console.error('Lỗi khi tạo thư mục con:', err);
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Không thể tạo thư mục con',
        })
      );
    }
    setOpenDialog(false);
  };

  const handleCreateLinkExam = async (
    examId: string,
    parentFolderId: string
  ) => {
    try {
      if (!courseId) return;
      const res = await createNewLinkExam(examId, courseId, parentFolderId);
      console.log('res', res);
      // reload the page
      window.location.reload();
    } catch (err: any) {
      console.error('Lỗi khi tạo link đề:', err);
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: err.response.data.message,
        })
      );
    }
    setOpenDialog(false);
  };

  const handleCreateNewItem = async (type: string) => {
    let parentFolderId: string = '';
    if (activeItem.type === 'subfolder') {
      parentFolderId = activeItem.itemId;
    } else {
      parentFolderId = courseId || '';
    }
    setDialogMaxWidth('xl');
    setDialogItemType(type === 'subfolder' ? 'subfolder' : 'other');

    try {
      if (type === 'exam') {
        // Lấy thông tin bài kiểm tra cuối cùng để tạo bài kiểm tra mới
        const exams = courseContent.items.filter(
          (item: any) => item.type === 'exam'
        );

        const lastExam = exams[0];

        let examData = defaultExam;
        if (
          lastExam &&
          lastExam.itemId !== undefined &&
          lastExam.duration !== undefined &&
          lastExam.executedAt !== undefined &&
          lastExam.isExercise !== undefined &&
          lastExam.subject !== undefined &&
          lastExam.title !== undefined &&
          lastExam.onlyForMembers !== undefined
        ) {
          examData.duration = lastExam.duration;
          examData.executedAt = lastExam.executedAt;
          examData.isExercise = lastExam.isExercise;
          examData.subject = lastExam.subject;
          // examData.title = lastExam.title;
          examData.title = '';
          examData.onlyForMembers = lastExam.onlyForMembers;
          examData.examId = lastExam.itemId;
        }

        setDialogContent(
          <ExamTabs
            mode="create"
            courseId={courseId}
            parentFolderId={parentFolderId}
            closeDialog={handleCloseDialog}
            isTeacher={isTeacher}
            examTemplate={examData}
          />
        );
      } else if (type === 'pdf' || type === 'video') {
        // Lấy thông tin pdf/video cuối cùng để tạo pdf/video mới
        const lastItem = courseContent.items
          .filter((item: any) => item.type === type)
          .pop();

        let itemData = {
          title: 'New Item',
          url: '',
          urlItem: null,
          onlyForMembers: true,
        };

        if (
          lastItem &&
          lastItem.title !== undefined &&
          lastItem.onlyForMembers !== undefined
        ) {
          itemData.title = lastItem.title;
          itemData.onlyForMembers = lastItem.onlyForMembers;
        }

        setDialogContent(
          <VideoPdfPage
            itemId=""
            mode="create"
            courseId={courseId}
            parentFolderId={parentFolderId}
            closeDialog={handleCloseDialog}
            type={type}
            isTeacher={isTeacher}
            itemTemplate={itemData}
          />
        );
      } else if (type === 'subfolder') {
        setDialogMaxWidth('sm');
        setDialogContent(
          <SubFolderDialogItem
            onSaveClick={(name: string) => {
              handleCreateSubFolder(name, parentFolderId);
            }}
            onClose={handleCloseDialog}
          />
        );
      } else if (type === 'linkexam') {
        setDialogContent(
          <SubFolderDialogItem
            onSaveClick={(examId: string) => {
              handleCreateLinkExam(examId, parentFolderId);
            }}
            onClose={handleCloseDialog}
            label="Exam ID"
            placeholder="000000"
          />
        );
      }
    } catch (err) {
      console.error('Lỗi khi tạo mục mới:', err);
      dispatch(setError('Không thể tạo mục mới'));
    } finally {
      setOpenDialog(true);
      setLoading(false);
      handleCloseMenu();
    }
  };

  const handleOpenDialog = (item: any) => {
    if (item.onlyForMembers && !isMember && !isTeacher) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Chỉ thành viên mới có thể truy cập mục này',
        })
      );
      return;
    }
    setOpenDialog(true);
    //Add one to item views
    if (item.type !== 'subfolder') {
      dispatch(
        updateCourseItem({
          ...item,
          views: item.views + 1,
          examTimes:
            item.type === 'exam' || item.type === 'linkexam'
              ? item.examTimes + 1
              : item.examTimes,
        })
      );
    }

    const type = item.type;
    setDialogItemType(type === 'subfolder' ? 'subfolder' : 'other');
    const id = item.itemId;
    if (type === 'exam' || type === 'linkexam') {
      setDialogContent(
        <ExamTabs
          examId={id}
          closeDialog={handleCloseDialog}
          isTeacher={isTeacher}
        />
      );
    } else if (type === 'video' || type === 'pdf') {
      setDialogContent(
        <VideoPdfPage
          itemId={id}
          mode="view"
          closeDialog={handleCloseDialog}
          type={type}
          isTeacher={isTeacher}
        />
      );
    } else if (type === 'subfolder') {
      setDialogContent(<></>);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const toggleSubfolder = (subfolderId: string) => {
    setOpenSubfolders((prev) =>
      prev.includes(subfolderId)
        ? prev.filter((id) => id !== subfolderId)
        : [...prev, subfolderId]
    );
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      const { _id, itemId, type } = activeItem;
      if (type === 'exam') {
        await deleteExamById(itemId);
      } else if (type === 'video') {
        await deleteVideoById(itemId);
      } else if (type === 'pdf') {
        await deletePDFById(itemId);
      } else if (type === 'subfolder') {
        // Xóa thư mục con
        await deleteSubFolderById(itemId);
      } else if (type === 'linkexam') {
        // Xóa link đề
        await deleteLinkExamById(_id);
      }
      dispatch(
        deleteCourseItem({
          itemId: itemId,
          type: type,
        })
      );
    } catch (error) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Không thể xóa khóa học',
        })
      );
    } finally {
      setLoadingDelete(false);
      setOpenConfirmation(false);
    }
  };

  const handleEdit = (id: string, type: string) => {
    setOpenDialog(true);
    setDialogItemType(type === 'subfolder' ? 'subfolder' : 'other');
    try {
      if (type === 'exam' || type === 'linkexam') {
        setDialogContent(
          <ExamTabs
            mode="edit"
            closeDialog={handleCloseDialog}
            examId={id}
            isTeacher={isTeacher}
          />
        );
      } else if (type === 'video' || type === 'pdf') {
        setDialogContent(
          <VideoPdfPage
            itemId={id}
            mode="edit"
            closeDialog={handleCloseDialog}
            type={type}
            isTeacher={isTeacher}
          />
        );
      } else if (type === 'subfolder') {
        setDialogContent(
          <SubFolderDialogItem
            onSaveClick={async (title: string) => {
              const newUpdatedSubFolder = await updateSubFolderById(id, {
                title: title,
              });
              // Tìm thư mục con trong danh sách và cập nhật tiêu đề
              let updatedSubFolder;
              for (let i = 0; i < courseContent.items.length; i++) {
                if (courseContent.items[i].itemId === id) {
                  updatedSubFolder = {
                    ...courseContent.items[i],
                    title: title,
                  };
                  break;
                }
              }
              if (updatedSubFolder)
                dispatch(updateCourseItem(updatedSubFolder));
              handleCloseDialog();
            }}
            onClose={handleCloseDialog}
            subFolderId={id}
          />
        );
      }
    } catch (err) {
      console.error('Lỗi khi tạo mục mới:', err);
      dispatch(setError('Không thể tạo mục mới'));
    } finally {
      setLoading(false);
      handleCloseMenu();
    }
  };

  const handleMove = (id: string, type: string) => {
    // Move item to another folder by open a list of folders (include the root folder)
    // and let user select the folder to move the item to
    setDialogContent(
      <Box p={2}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Chọn thư mục để di chuyển
        </Typography>
        <List>
          {courseContent.items
            .filter((item: any) => item.type === 'subfolder')
            .map((item: any) => (
              <ListItem
                key={item.itemId}
                button
                onClick={async () => {
                  try {
                    await updateItemParentFolderId(
                      activeItem.itemId,
                      item.itemId,
                      activeItem.type
                    );
                    handleCloseDialog();
                    // reload the page
                    window.location.reload();
                  } catch (error) {
                    console.error('Error updating item parent folder:', error);
                  }
                }}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          <ListItem key={courseId || 'root'} button onClick={() => {}}>
            <ListItemText primary="Thư mục gốc" />
          </ListItem>
        </List>
      </Box>
    );
    setOpenDialog(true);
  };

  const renderItems = (items: any[] = [], marginLeft: number) => {
    //Create an array of heightSubtract includes 59px with length of items.length
    const heightSubtract = [...Array(items.length)].map((_, i) => 59);
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type === 'subfolder') {
        const lastItem = item.items[item.items.length - 1];
        if (
          lastItem &&
          lastItem.type === 'subfolder' &&
          openSubfolders.includes(lastItem.itemId)
        ) {
          heightSubtract[i] = 60 * lastItem.items.length + 59;
        }
      }
    }

    return (
      <List sx={{ py: 0, ml: { marginLeft } }}>
        {items.map((item, index) => (
          <Box key={item.type + item.itemId} position="relative">
            {marginLeft > 0 && (
              <Divider
                orientation="horizontal"
                sx={{
                  position: 'absolute',
                  left: -2,
                  top: 30,
                  width: 30,
                  backgroundColor: 'text.secondary',
                  opacity: 0.3,
                }}
              />
            )}
            <Divider
              orientation="vertical"
              sx={{
                position: 'absolute',
                left: 27,
                top: 30,
                backgroundColor: 'text.secondary',
                opacity: 0.3,
                // calculate the height of the divider by 100% subtract 59px multiplied by the number of item.items.length
                height: `calc(100% - ${heightSubtract[index]}px)`,
              }}
            />
            <ListItem
              sx={{
                padding: 0,
              }}
            >
              <>
                <Button
                  //disabled={item.type !== 'subfolder' && item.onlyForMembers}
                  fullWidth
                  variant="outlined"
                  onClick={() =>
                    item.type === 'subfolder'
                      ? toggleSubfolder(item.itemId)
                      : handleOpenDialog(item)
                  }
                  sx={{
                    opacity:
                      item.onlyForMembers && !isMember && !isTeacher ? 0.5 : 1,
                    height: 60,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textTransform: 'none',
                    borderRadius: 5,
                    border: 'none',
                    '&:hover': {
                      border: 'none',
                      '& .circle-icon': {
                        // Target the Box with the class "circle-icon"
                        width: 40, // Increase size of Box when button is hovered
                        height: 40,
                      },
                    },
                    '&.Mui-disabled': {
                      border: 'none', // Set border to none when button is disabled
                      opacity: 0.7, // Reduce opacity when button is disabled
                    },
                    // backgroundColor:
                    //   !isDesktop &&
                    //   (item.type === 'exam' || item.type === 'linkexam') &&
                    //   item.result
                    //     ? alpha(yellow[500], 0.1)
                    //     : 'transparent',
                  }}
                >
                  <Box display="flex" alignItems="center" flex="1">
                    <Box
                      className="circle-icon"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        marginRight: 1,
                        marginLeft: 0.5,
                        minWidth: 0, // Xóa minWidth mặc định của nút
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        color: 'background.paper',
                        transition: 'all 0.3s ease', // Smooth transition effect
                        position: 'relative',
                      }}
                    >
                      {(item.type === 'exam' || item.type === 'linkexam') &&
                        (item.isExercise ? (
                          <ImportContactsIcon fontSize="small" />
                        ) : (
                          <DescriptionIcon fontSize="small" />
                        ))}

                      {item.type === 'video' && (
                        <YouTubeIcon fontSize="small" />
                      )}
                      {item.type === 'pdf' && (
                        <PictureAsPdfIcon fontSize="small" />
                      )}
                      {item.type === 'subfolder' &&
                        (openSubfolders.includes(item.itemId) ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        ))}
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      flex="1"
                      justifyContent="space-between"
                    >
                      {/* <ListItemText
                      primary={item.title}
                      primaryTypographyProps={{
                        noWrap: true,
                        color: 'text.primary',
                        fontWeight: '500', // Làm tiêu đề đậm
                        variant: 'subtitle1', // Điều chỉnh kích thước tiêu đề
                        marginBottom: 0, // Giảm khoảng cách giữa tiêu đề và hàng dưới
                      }}
                    /> */}
                      <Box
                        display="flex"
                        flexDirection="row"
                        mb={0.5}
                        alignItems="center"
                      >
                        <WrapText variant="subtitle1" fontWeight={600}>
                          {item.title}
                        </WrapText>
                        {(item.type === 'exam' || item.type === 'linkexam') &&
                          !item.isExercise && (
                            <>
                              {(() => {
                                const now = new Date();
                                const executedAt = new Date(item.executedAt);
                                const endAt = new Date(
                                  executedAt.getTime() + item.duration * 60000
                                ); // item.duration in minutes

                                if (now > endAt) {
                                  return (
                                    <WrapText
                                      variant="caption"
                                      fontWeight={600}
                                      sx={{ ml: 1, color: 'error.main' }}
                                    >
                                      {' '}
                                      Đã kết thúc
                                    </WrapText>
                                  );
                                } else if (now < executedAt) {
                                  return (
                                    <WrapText
                                      variant="caption"
                                      fontWeight={600}
                                      sx={{ ml: 1, color: 'success.main' }}
                                    >
                                      Diễn ra sau{' '}
                                      {formatDistanceToNow(executedAt, {
                                        addSuffix: true,
                                        locale: vi,
                                      })}
                                    </WrapText>
                                  );
                                } else {
                                  return (
                                    <WrapText
                                      variant="caption"
                                      fontWeight={600}
                                      sx={{ ml: 1, color: 'success.main' }}
                                    >
                                      {' '}
                                      Đang diễn ra
                                    </WrapText>
                                  );
                                }
                              })()}
                            </>
                          )}

                        {item.result && isDesktop && isViewScore && (
                          <WrapText
                            variant="caption"
                            fontWeight={600}
                            sx={{ mx: 1, color: 'warning.main' }}
                          >
                            {' '}
                            {item.result.score} điểm
                            {item.result.rank && ` - Hạng ${item.result.rank}`}
                          </WrapText>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        color="text.secondary"
                      >
                        {(item.type === 'exam' || item.type === 'linkexam') && (
                          <WrapText variant="caption">
                            {' '}
                            {item.examTimes} lượt xem
                          </WrapText>
                        )}
                        {!(item.type === 'exam' || item.type === 'linkexam') &&
                          item.type !== 'subfolder' && (
                            <WrapText variant="caption">
                              {' '}
                              {item.views} lượt xem
                            </WrapText>
                          )}

                        {item.result && !isDesktop && isViewScore ? (
                          <>
                            <Typography variant="caption" sx={{ mx: 1 }}>
                              {' '}
                              •
                            </Typography>
                            <WrapText
                              variant="caption"
                              fontWeight={600}
                              sx={{ color: 'warning.main' }}
                            >
                              {' '}
                              {item.result.score} điểm
                              {item.result.rank &&
                                ` - Hạng ${item.result.rank}`}
                            </WrapText>
                          </>
                        ) : (
                          item.createdAt &&
                          item.type !== 'subfolder' && (
                            <>
                              <Typography variant="caption" sx={{ mx: 1 }}>
                                {' '}
                                •
                              </Typography>
                              <WrapText variant="caption">
                                {' '}
                                {formatDistanceToNow(new Date(item.createdAt), {
                                  addSuffix: true,
                                  locale: vi,
                                })}
                              </WrapText>
                            </>
                          )
                        )}
                        {/* {item.createdAt && item.type !== 'subfolder' && (
                          <>
                            <Typography variant="caption" sx={{ mx: 1 }}>
                              {' '}
                              •
                            </Typography>
                            <WrapText variant="caption">
                              {' '}
                              {formatDistanceToNow(new Date(item.createdAt), {
                                addSuffix: true,
                                locale: vi,
                              })}
                            </WrapText>
                          </>
                        )} */}
                      </Box>
                    </Box>
                  </Box>

                  {isTeacher && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {item.type !== 'subfolder' && (
                        <>
                          {item.type === 'linkexam' && (
                            <Tooltip
                              title={
                                <>
                                  <Typography variant="subtitle1">
                                    Mục này được link từ bài kiểm tra khác
                                  </Typography>
                                </>
                              }
                              arrow
                            >
                              <LinkIcon
                                style={{
                                  marginLeft: 5,
                                  cursor: 'pointer',
                                  fontSize: 16,
                                }}
                              />
                            </Tooltip>
                          )}
                          {item.cannotViewAnswerBeforeFirstExam && (
                            <Tooltip
                              title={
                                <>
                                  <Typography variant="subtitle1">
                                    Mục này không thể xem đáp án trước khi làm
                                    bài kiểm tra lần đầu
                                  </Typography>
                                </>
                              }
                              arrow
                            >
                              <PriorityHigh
                                style={{
                                  marginLeft: 5,
                                  cursor: 'pointer',
                                  fontSize: 16,
                                }}
                              />
                            </Tooltip>
                          )}
                          {!item.onlyForMembers && (
                            <Tooltip
                              title={
                                <>
                                  <Typography variant="subtitle1">
                                    Mục này không yêu cầu thành viên
                                  </Typography>
                                </>
                              }
                              arrow
                            >
                              <LockOpenOutlinedIcon
                                style={{
                                  marginLeft: 5,
                                  cursor: 'pointer',
                                  fontSize: 16,
                                }}
                              />
                            </Tooltip>
                          )}
                        </>
                      )}
                      <IconButton
                        aria-label="more"
                        aria-controls="course-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          setActiveItem({
                            itemId: item.itemId,
                            type: item.type,
                            _id: item._id || '',
                          });
                          handleOpenMenu2(e);
                          e.stopPropagation();
                        }}
                        color="primary"
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                </Button>
              </>
            </ListItem>
            {item.type === 'subfolder' && (
              <Collapse
                in={openSubfolders.includes(item.itemId)}
                timeout="auto"
                unmountOnExit
              >
                {renderItems(item.items, 30)}
              </Collapse>
            )}
          </Box>
        ))}
      </List>
    );
  };

  if (loading) {
    return <LoadingDisplay loadingMessage="Đang tải chi tiết khóa học..." />;
  }

  if (error) {
    return <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />;
  }

  const RightPanel = (
    <Box px={!isDesktop ? 1 : 0}>
      {/* {!isDesktop && (
        <Divider
          sx={{
            my: 1,
            backgroundColor: 'text.secondary',
            opacity: 0.3,
          }}
        />
      )} */}
      {isDesktop && (
        <ImageComponent
          imageUrl={courseContent.image}
          title={'title'}
          sx={{ mb: 3 }}
        />
      )}

      {/* <CourseInfoSection
        courseContent={courseContent}
        isTeacher={isTeacher}
        onAddNewLink={(link: string) => handleAddLink(link)}
        onAddNewContactLink={(link: string) => handleAddContactLink(link)}
        onDeleteLink={(link: string) => handleDeleteLink(link)}
        onAddNewLearningObjective={(objective: string) =>
          handleAddLearningObjective(objective)
        }
        onDeleteLearningObjective={(objective: string) =>
          handleDeleteLearningObjective(objective)
        }
        isDesktop={isDesktop || false}
      /> */}

      <Box mb={isDesktop ? 2 : 1}>
        {isDesktop ? (
          <CourseInfoSection
            courseContent={courseContent}
            isTeacher={isTeacher}
            onAddNewLink={(link: string) => handleAddLink(link)}
            onAddNewContactLink={(link: string) => handleAddContactLink(link)}
            onDeleteLink={(link: string) => handleDeleteLink(link)}
            onAddNewLearningObjective={(objective: string) =>
              handleAddLearningObjective(objective)
            }
            onDeleteLearningObjective={(objective: string) =>
              handleDeleteLearningObjective(objective)
            }
            isDesktop={isDesktop || false}
          />
        ) : (
          <></>
          // <TruncatedTextWithDialog
          //   // align center and add underline
          //   sx={{
          //     textAlign: 'center',
          //     textTransform: 'uppercase',
          //   }}
          //   content="Thông tin khóa"
          //   dialogContent={
          //     <CourseInfoSection
          //       courseContent={courseContent}
          //       isTeacher={isTeacher}
          //       onAddNewLink={(link: string) => handleAddLink(link)}
          //       onAddNewContactLink={(link: string) =>
          //         handleAddContactLink(link)
          //       }
          //       onDeleteLink={(link: string) => handleDeleteLink(link)}
          //       onAddNewLearningObjective={(objective: string) =>
          //         handleAddLearningObjective(objective)
          //       }
          //       onDeleteLearningObjective={(objective: string) =>
          //         handleDeleteLearningObjective(objective)
          //       }
          //       isDesktop={isDesktop || false}
          //     />
          //   }
          // />
        )}
      </Box>
      {/* {!isDesktop && (
        <Divider
          sx={{
            my: 1,
            mb: 2,
            backgroundColor: 'text.secondary',
            opacity: 0.3,
          }}
        />
      )} */}

      <CourseActions
        user={user}
        courseId={courseId || ''}
        contactLink={courseContent.contactLink}
        price={courseContent.price || 0}
        isMember={isMember}
        setIsMember={setIsMember}
        rate={rate}
        setRate={setRate}
        course={courseContent}
        isTeacher={isTeacher}
      />
    </Box>
  );

  return (
    <Container maxWidth={'xl'}>
      <Box
        display="flex"
        justifyContent="space-between"
        pt={2}
        // flexDirection={isDesktop ? 'row' : 'column'}
        // height={isDesktop ? 'auto' : 'calc(100vh - 64px)'}
      >
        <Box
          flex="1"
          maxWidth={isDesktop ? '70%' : '100%'}
          padding={isDesktop ? 2 : 0}
          sx={{ position: 'relative' }}
        >
          {/* Render nút Thêm theo điều kiện */}
          {isTeacher && (
            <>
              <AddButton
                sx={{
                  position: 'absolute',
                  top: {
                    xs: 10,
                    md: 30,
                  },
                  right: {
                    xs: 10,
                    md: 30,
                  },
                  zIndex: 1,

                  // Merge custom styles with default styles
                }}
                onClick={(e) => {
                  handleOpenMenu(e);
                  setActiveItem({
                    itemId: courseId || '',
                    type: 'course',
                    _id: '',
                  });
                }}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={() => handleCreateNewItem('exam')}>
                  <ListItemIconCustom>
                    <DescriptionIcon fontSize="small" />
                  </ListItemIconCustom>
                  <Typography variant="caption">Đề</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleCreateNewItem('pdf')}>
                  <ListItemIconCustom>
                    <PictureAsPdfIcon fontSize="small" />
                  </ListItemIconCustom>
                  <Typography variant="caption">PDF</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleCreateNewItem('video')}>
                  <ListItemIconCustom>
                    <YouTubeIcon fontSize="small" />
                  </ListItemIconCustom>
                  <Typography variant="caption">Video</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleCreateNewItem('subfolder')}>
                  <ListItemIconCustom>
                    <FolderIcon fontSize="small" />
                  </ListItemIconCustom>
                  <Typography variant="caption">Thư mục mới</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
          <Paper
            elevation={2}
            sx={{
              padding: 3,
              py: 1.5,
              backgroundColor: 'background.paperDarker',
              color: 'background.default',
              borderRadius: 2,
            }}
          >
            {/* <Typography
              variant="h3"
              sx={{ mb: 1, color: 'background.default' }}
            >
              {courseContent.title}
            </Typography> */}
            <TruncatedTextWithDialog
              // align center and add underline
              sx={{ mb: 1, color: 'background.default', width: 'auto' }}
              variant="h3"
              content={courseContent.title}
              dialogContent={
                <CourseInfoSection
                  courseContent={courseContent}
                  isTeacher={isTeacher}
                  onAddNewLink={(link: string) => handleAddLink(link)}
                  onAddNewContactLink={(link: string) =>
                    handleAddContactLink(link)
                  }
                  onDeleteLink={(link: string) => handleDeleteLink(link)}
                  onAddNewLearningObjective={(objective: string) =>
                    handleAddLearningObjective(objective)
                  }
                  onDeleteLearningObjective={(objective: string) =>
                    handleDeleteLearningObjective(objective)
                  }
                  isDesktop={isDesktop || false}
                />
              }
            />
            <Typography
              variant="body2"
              paragraph
              sx={{ color: 'background.default' }}
            >
              {courseContent.description}
            </Typography>
            <Stack direction="row" spacing={1} mt={1} mb={1}>
              <img
                src={courseContent.createdBy.avatar}
                alt="avatar"
                style={{ height: 40, width: 40, borderRadius: '50%' }}
                referrerPolicy="no-referrer"
              />
              <Stack direction="column" spacing={0}>
                <CourseStats>
                  <Typography variant="subtitle2">
                    Được tạo bởi {courseContent.createdBy.name}
                  </Typography>
                </CourseStats>
                <CourseStats>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">
                      Cập nhật lần cuối:{' '}
                      {formatDistanceToNow(
                        new Date(courseContent.lastUpdatedAt),
                        {
                          addSuffix: true,
                          locale: vi,
                        }
                      )}
                    </Typography>
                  </Stack>
                </CourseStats>
              </Stack>
            </Stack>
          </Paper>

          <div style={{ marginTop: '8px' }}>
            <FormControlLabel
              sx={{ ml: 1 }}
              control={
                <Checkbox
                  checked={isViewScore}
                  onChange={() => setIsViewScore(!isViewScore)}
                />
              }
              label={
                <Typography variant="subtitle2">Hiển thị điểm số</Typography>
              }
            />
            {renderItems(courseContent.items, 0)}
          </div>
        </Box>

        {isDesktop && (
          <Box flex="1" maxWidth="28%" minWidth={240}>
            {RightPanel}
          </Box>
        )}
      </Box>
      {!isDesktop && <Box mt={2}> {RightPanel}</Box>}
      <DialogForm
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogTitle}
        url={dialogUrl}
        content={dialogContent}
        maxWidth={dialogMaxWidth}
        type={dialogItemType}
      />
      <ConfirmDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => handleDelete()}
        title="Xóa mục"
        content="Bạn có chắc chắn muốn xóa mục này không?"
        loading={loadingDelete}
      />

      {/* Menu with Edit and Delete actions */}
      <Menu
        id={activeItem.itemId + activeItem.type}
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleCloseMenu2}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();

            handleEdit(activeItem.itemId, activeItem.type);
            setAnchorEl2(null);
          }}
        >
          <ListItemIconCustom>
            <EditIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleMove(activeItem.itemId, activeItem.type);
            setAnchorEl2(null);
          }}
        >
          <ListItemIconCustom>
            <DriveFileMoveIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">Move</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setActiveItem({
              itemId: activeItem.itemId,
              type: activeItem.type,
              _id: activeItem._id || '',
            });
            setOpenConfirmation(true);
            setAnchorEl2(null);
          }}
        >
          <ListItemIconCustom>
            <DeleteIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">Delete</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => handleCreateNewItem('exam')}
          sx={activeItem.type === 'subfolder' ? {} : { display: 'none' }}
        >
          <ListItemIconCustom>
            <DescriptionIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">Đề</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleCreateNewItem('pdf')}
          sx={activeItem.type === 'subfolder' ? {} : { display: 'none' }}
        >
          <ListItemIconCustom>
            <PictureAsPdfIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">PDF</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleCreateNewItem('video')}
          sx={activeItem.type === 'subfolder' ? {} : { display: 'none' }}
        >
          <ListItemIconCustom>
            <YouTubeIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">Video</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleCreateNewItem('subfolder')}
          sx={activeItem.type === 'subfolder' ? {} : { display: 'none' }}
        >
          <ListItemIconCustom>
            <FolderIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">Thư mục mới</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleCreateNewItem('linkexam')}
          sx={activeItem.type === 'subfolder' ? {} : { display: 'none' }}
        >
          <ListItemIconCustom>
            <LinkIcon fontSize="small" />
          </ListItemIconCustom>
          <Typography variant="caption">Link đề</Typography>
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default CoursesPage;

import React, { useEffect, useRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import QuestionComponent from './QuestionComponent';
import { Exam, Question, Result, VideoLink } from './type';
import MainButton from '../Courses/MainButton';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

interface QuestionListProps {
  questions: Question[];
  activeQuestion: Question | null;
  viewAnswer?: boolean;
  showResult?: boolean;
  result?: Result | null;
  formatSize?: number;
  isExamming?: boolean;
  setResult?: (result: Result) => void;
  listLinks?: VideoLink[];
  loadingSubmit?: boolean;
  handleSubmit?: () => void;
  isMobile?: boolean;
}

const QuestionList: React.FC<QuestionListProps> = ({
  questions,
  activeQuestion,
  viewAnswer = false,
  showResult = false,
  result,
  formatSize,
  isExamming,
  setResult,
  listLinks,
  loadingSubmit,
  handleSubmit,
  isMobile = false,
}) => {
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollToWithOffset = (
    container: HTMLElement,
    targetTop: number,
    offset: number
  ) => {
    const start = container.scrollTop;
    const distance = targetTop - offset - start;
    const duration = 500; // Animation duration in ms
    let startTime: number | null = null;

    const animateScroll = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1); // Progress between 0 and 1
      container.scrollTop = start + distance * progress;

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  // const handleDownload = () => {
  //   if (containerRef.current) {
  //     downloadAsPDF(containerRef.current, 'component.pdf');
  //   }
  // };

  useEffect(() => {
    if (activeQuestion) {
      const index = questions.findIndex(
        (question) => question.questionId === activeQuestion.questionId
      );
      const targetElement = questionRefs.current[index];
      if (targetElement && containerRef.current) {
        const container = containerRef.current;
        const targetOffsetTop = targetElement.offsetTop;
        scrollToWithOffset(container, targetOffsetTop, 5);
      }
    }
  }, [activeQuestion, questions]);

  return (
    <Box
      ref={containerRef}
      sx={{ height: '100%', overflow: 'auto', scrollBehavior: 'smooth' }}
    >
      {viewAnswer &&
        listLinks &&
        listLinks.length > 0 &&
        listLinks.map((link, index) => (
          <Box key={index} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {link.title}
            </Typography>
            <div
              style={{
                position: 'relative',
                width: '100%',
                paddingTop: '56.25%',
                marginBottom: '1rem',
              }}
            >
              <iframe
                key={index}
                src={link.link}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
                title={`Link ${index}`}
              />
            </div>
          </Box>
        ))}
      {questions.map((question, index) => (
        <div
          key={index}
          ref={(el) => (questionRefs.current[index] = el)} // Assign refs dynamically
        >
          {isExamming ? (
            result && (
              <QuestionComponent
                question={question}
                answer={
                  result.answers.find(
                    (answer) => answer.questionId === question.questionId
                  )?.answer
                }
                editable={true}
                onAnswerChange={(answer) => {
                  const index = questions.indexOf(question);
                  const newAnswers = [...result.answers];
                  newAnswers[index].answer = answer;
                  if (
                    (question.type === 'mcq' && answer.length > 0) ||
                    (question.type === 'mctf' &&
                      answer.length > 0 &&
                      !answer.includes('_'))
                  ) {
                    newAnswers[index].check = true;
                  }
                  setResult && setResult({ ...result, answers: newAnswers });
                }}
                formatSize={formatSize}
                checkCorrect={false}
              />
            )
          ) : (
            <QuestionComponent
              key={index}
              question={
                viewAnswer
                  ? question
                  : {
                      ...question,
                      correctAnswer: showResult ? question.correctAnswer : '',
                      explain: '',
                      explainImageUrl: '',
                    }
              }
              editable={false}
              answer={
                showResult
                  ? result?.answers.find(
                      (answer) => answer.questionId === question.questionId
                    )?.answer
                  : undefined
              }
              checkCorrect={
                showResult
                  ? result?.answers.find(
                      (answer) => answer.questionId === question.questionId
                    )?.check
                  : undefined
              }
              formatSize={formatSize}
            />
          )}
        </div>
      ))}
      {loadingSubmit !== undefined && handleSubmit && isMobile && (
        <MainButton
          variant="first"
          text="Nộp bài"
          icon={<TextSnippetIcon />}
          isLoading={loadingSubmit}
          onClick={() => handleSubmit()}
          sx={{
            my: 1,
            width: 'calc(100% - 2rem)',
            mx: '1rem',
            borderRadius: 4,
          }}
        />
      )}
    </Box>
  );
};

export default QuestionList;

// import React, { useEffect, useRef } from 'react';
// import { Box, Button } from '@mui/material';

// import QuestionComponent from './QuestionComponent';
// import { Exam, Question, Result } from './type';
// import { downloadAsPDF } from './pdfUtils';

// interface QuestionListProps {
//   questions: Question[];
//   activeQuestion: Question | null;
//   viewAnswer?: boolean;
//   showResult?: boolean;
//   result?: Result | null;
//   formatSize?: number;
//   exam?: Exam | null;
//   isExamming?: boolean;
//   setResult?: (result: Result) => void;
// }

// const QuestionList: React.FC<QuestionListProps> = ({
//   questions,
//   activeQuestion,
//   viewAnswer = false,
//   showResult = false,
//   result,
//   formatSize,
//   exam,
//   isExamming,
//   setResult,
// }) => {
//   const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
//   const containerRef = useRef<HTMLDivElement | null>(null);

//   const handleDownload = () => {
//     if (containerRef.current) {
//       downloadAsPDF(containerRef.current, 'component.pdf');
//     }
//   };

//   return (
//     <Box>
//       <Button variant="contained" color="primary" onClick={handleDownload}>
//         Download PDF
//       </Button>
//       <Box
//         ref={containerRef}
//         sx={{ height: '100%', overflow: 'auto', scrollBehavior: 'smooth' }}
//       >
//         {questions.map((question, index) => (
//           <div
//             key={index}
//             ref={(el) => (questionRefs.current[index] = el)} // Assign refs dynamically
//           >
//             {isExamming ? (
//               result && (
//                 <QuestionComponent
//                   question={question}
//                   answer={
//                     result.answers.find(
//                       (answer) => answer.questionId === question.questionId
//                     )?.answer
//                   }
//                   editable={true}
//                   onAnswerChange={(answer) => {
//                     const index = questions.indexOf(question);
//                     const newAnswers = [...result.answers];
//                     newAnswers[index].answer = answer;
//                     if (
//                       (question.type === 'mcq' && answer.length > 0) ||
//                       (question.type === 'mctf' &&
//                         answer.length > 0 &&
//                         !answer.includes('_'))
//                     ) {
//                       newAnswers[index].check = true;
//                     }
//                     setResult && setResult({ ...result, answers: newAnswers });
//                   }}
//                   formatSize={formatSize}
//                   checkCorrect={false}
//                 />
//               )
//             ) : (
//               <QuestionComponent
//                 key={index}
//                 question={
//                   viewAnswer
//                     ? question
//                     : {
//                         ...question,
//                         correctAnswer: showResult ? question.correctAnswer : '',
//                         explain: '',
//                         explainImageUrl: '',
//                       }
//                 }
//                 editable={false}
//                 answer={
//                   showResult
//                     ? result?.answers.find(
//                         (answer) => answer.questionId === question.questionId
//                       )?.answer
//                     : undefined
//                 }
//                 checkCorrect={
//                   showResult
//                     ? result?.answers.find(
//                         (answer) => answer.questionId === question.questionId
//                       )?.check
//                     : undefined
//                 }
//                 formatSize={formatSize}
//               />
//             )}
//           </div>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default QuestionList;
